



















import { Vue, Watch, Prop, Component } from "vue-property-decorator";
import store from "../../../store";
import { AxiosResponse } from "axios";
import { VAutocomplete } from "vuetify/lib";
import { EventApi } from "@/networks/EventApi";
import { debounce } from "vue-debounce";

@Component({
  components: {
    VAutocomplete
  }
})
export default class EventAutoComplete extends Vue {
  @Prop({ default: 0 })
  defaultValue = 0;
  @Prop({ default: "رویداد" })
  label: string | undefined;

  private events: Array<Record<string, unknown>> = [];
  private eventId = 0;

  private isLoading = false;
  private search = "";

  mounted() {
    window.setTimeout(() => {
      this.eventId = this.defaultValue;
    }, 1000);
  }

  public async event(): Promise<void> {
    this.isLoading = true;
    try {
      const res: AxiosResponse = await new EventApi().searchEvent({
        q: this.search,
        defaultValue: this.defaultValue
      });
      if (this.defaultValue == 0) {
        this.events = [{ text: "هیچکدام", id: 0 }].concat(
          res.data.data.map((x: any) => {
            x.text = `${x.user ? x.user.fname : ""} ${
              x.user ? x.user.lname : ""
            } - ${x.title} - ${x.type}`;
            return x;
          })
        );
      } else {
        this.events = res.data.data.map((x: any) => {
          x.text = `${x.user ? x.user.fname : ""} ${
            x.user ? x.user.lname : ""
          } - ${x.title} - ${x.type}`;
          return x;
        });
      }
    } catch (error: any) {
      store.commit("showError", {
        message: error.response.data.message || "خطای نامشخص",
        color: "danger"
      });
    } finally {
      this.isLoading = false;
    }
  }

  private _timerId = 0;
  fetchEntriesDebounced(): void {
    // cancel pending call
    clearTimeout(this._timerId);

    // delay new call 500ms
    this._timerId = window.setTimeout(() => {
      this.event();
    }, 700);
  }

  @Watch("search")
  public onSearchChanged(val: string, old: string) {
    if (val != old && this.eventId == 0) {
      this.fetchEntriesDebounced();
    }
  }

  @Watch("eventId")
  public onPageChanged() {
    this.$emit("change-value", this.eventId);
  }
}
