












































import { Vue, Watch, Prop, Component } from "vue-property-decorator";
import GeneralApi from "@/networks/GeneralApi";
import store from "../../../store";
import { AxiosResponse } from "axios";
import { VAutocomplete } from "vuetify/lib";

@Component({
  components: {
    VAutocomplete
  }
})
export default class Country extends Vue {
  @Prop({})
  defaultValue: Array<string | number> | number | undefined;
  @Prop({ default: "0" })
  single: string | undefined;
  @Prop({ default: "کشور" })
  label: string | undefined;

  private countries: Array<Record<string, unknown>> = [];
  private countryId: Array<string | number> | number | undefined = [];
  private baseUrl = process.env.VUE_APP_URL || "";
  private loading = false;
  private search = "";

  mounted() {
    this.country();
    if (this.defaultValue) {
      this.countryId = this.defaultValue;
    }
  }

  public async country(q = ""): Promise<void> {
    try {
      const res: AxiosResponse = await new GeneralApi().countries({ q });
      this.countries = res.data.data;
    } catch (error: any) {
      store.commit("showError", {
        message: error.response.data.message || "خطای نامشخص",
        color: "danger"
      });
    }
  }

  @Watch("countryId")
  public onPageChanged() {
    console.log("here is updated!", this.countryId);
    if (this.countryId) {
      console.log("if");
      this.$emit("change-value", this.countryId);
    }
  }

  @Watch("search")
  public onSearchChanged() {
    this.country(this.search);
  }

  @Watch("defaultValue")
  public onDefaultValueChanged(val: any) {
    this.countryId = val;
  }
}
