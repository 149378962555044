





















































































































import { Component, Vue } from "vue-property-decorator";
import store from "../../../store";
import DatetimePicker from "vue-persian-datetime-picker";
import Office from "../../components/inputs/office.vue";
import UserAutoComplete from "@/views/components/inputs/user.vue";
import EventAutoComplete from "@/views/components/inputs/event.vue";
import Enum from "@/config/enum";
import Country from "@/views/components/inputs/country.vue";
import { ContractApi } from "@/networks/ContractApi";
import FileUploader from "@/views/components/inputs/fileUploader.vue";
import DatePicker from "vue-persian-datetime-picker";
import moment from "moment-jalaali";

@Component({
  components: {
    FileUploader,
    DatetimePicker,
    office: Office,
    UserAuto: UserAutoComplete,
    EventAuto: EventAutoComplete,
    country: Country,
    DatePicker
  }
})
export default class Contract extends Vue {
  private paymentTypes = Enum.TypePayment;
  private typeCurrency = Enum.currencies;
  private paymentNames = ["پیش پرداخت قرارداد", "قسط قرارداد", "تسویه قرارداد"];
  private payment: any = {
    name: null,
    status: 0
  };
  private errors: Record<string, Array<string>> = {};

  PersianDate(date: any) {
    return moment(date).format("jYYYY/jMM/jDD");
  }

  public async submit(): Promise<void> {
    try {
      if (this.payment.name !== "قسط قرارداد") {
        this.payment.status = 1;
      }
      console.log(
        this.payment.due_date,
        this.PersianDate(this.payment.due_date)
      );
      const form = new FormData();
      form.append("name", this.payment.name);
      form.append("status", this.payment.status);
      if (this.payment.file) {
        form.append("file", this.payment.file);
      }
      form.append("due_date", this.PersianDate(this.payment.due_date));
      form.append("price", this.payment.price);
      form.append("payment_type", this.payment.payment_type);
      form.append("price_of_currency", this.payment.price_of_currency);
      form.append("type_of_currency", this.payment.type_of_currency);
      await new ContractApi().addTransaction(this.$route.params.id, form);
      store.commit("showError", {
        message: "پرداخت با موفقیت ثبت شد",
        color: "success"
      });
      this.payment = {};
    } catch (err: any) {
      store.commit("showError", {
        message: err.message,
        color: "danger"
      });
    }
  }
}
